.navbar {
	min-height: 100px;
	box-shadow: 0 6px 17px 1px rgba(0, 68, 100, 0.15);
	background-color: #ffffff;
	padding-top: 25px;
	border: none;
	border-radius: 0;
	margin-bottom: 0;
	z-index: 100;
	@media screen and (max-width: 991px) {
		padding-top: 12px;
		min-height: 63px; } }

.navbar-brand {
	padding: 0 15px;
	margin-top: -3px; }

.logo {
	width: 243px;
	height: 61px;
	@media screen and (max-width: 991px) {
		width: 190px;
		height: auto; } }

.navbar-default .navbar-nav > li > a {
	color: #050000;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.28px;
	padding-right: 2px;
	padding-left: 5px;
	&:hover {
		text-decoration: underline; }
	@media screen and (max-width: 991px) {
		font-size: 12px;
		padding-top: 10px;
		margin-right: 10px; }
	@media screen and (max-width: 767px) {
		padding-left: 15px; } }

.navbar-toggle {
	@media screen and (max-width: 991px) {
		margin-top: 3px; } }

.navbar-right-minwidth a {
	color: $blue;
	font-size: 24px;
	font-weight: 700;
	padding-bottom: 5px;
	margin-top: -5px;
	@media screen and (max-width: 1230px) {
		font-size: 18px; } }

.navbar-right-minwidth span {
	display: block;
	color: #747474;
	font-size: 14px;
	letter-spacing: 0.28px; }

.navbar-right .btn-blue {
	width: 153px;
	margin-bottom: 20px;
	&:hover {
		color: #232323;
		box-shadow: none;
		background-color: #fff;
		border: 1px solid #599dbc; } }

.support {
	display: inline-block;
	margin-right: 15px;
	margin-top: 4px;
	span {
		color: #747474;
		font-size: 14px;
		letter-spacing: 0.28px;
		margin-left: 10px; } }

.tech-sup-hotline {
	float: right; }

.tech-sup-hotline a,
.tech-sup a {
	font-size: 14px;
	font-weight: 700; }

.navbar-right-maxwidth {
	@media screen and (max-width: 1240px) {
		display: none; }
	@media screen and (max-width: 767px) {
		display: block;
		.support {
			display: none; } } }

.navbar-right-minwidth {
	display: none;
	@media screen and (max-width: 1240px) {
		display: block; }
	@media screen and (max-width: 991px) {
		display: none; } }

.nav-placeholder {
	height: 100px; }

#nav-wraper.default {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99; }
