.registration-rent {
	h1 {
		margin-bottom: 25px; } }

.registration-section {
	padding-bottom: 50px; }

.product-container {
	display: inline-block;
	width: 148px;
	height: 89px;
	border: 1px solid #e8e8e8;
	background-color: #ffffff;
	overflow: hidden;
	float: left;
	margin-right: 20px;
	margin-bottom: 40px;
	img {
		display: block;
		height: 89px;
		width: auto;
		margin: auto; } }

.product-name {
	float: left;
	color: #282828;
	font-size: 16px;
	margin-top: 40px;
	@media screen and (max-width: 600px) {
		display: block;
		width: 80%;
		margin-top: 0;
		margin-bottom: 40px; } }

.product-price {
	float: right;
	font-size: 16px;
	text-transform: uppercase;
	color: #4a4a4a;
	font-family: $RobotoCondenced;
	font-weight: 700;
	margin-right: -100px;
	@media screen and (max-width: 1199px) {
		margin-right: 0;
		margin-top: 3px; } }

.product-price__mart {
	margin-top: 40px;
	@media screen and (max-width: 600px) {
		margin-top: 0; } }

.related-block {
	clear: both; }

.my-col {
	@media screen and (max-width: 1229px) {
		width: 100%; } }

.related-block-title {
	color: #282828;
	font-size: 16px;
	line-height: 22px;
	text-transform: uppercase;
	margin-bottom: 28px;
	font-weight: 400; }

.checkbox-label {
	color: #282828;
	font-size: 16px;
	font-weight: 300;
	line-height: 40px;
	margin-top: -5px;
	@media screen and (max-width: 480px) {
		position: relative;
		margin-left: 20px;
		line-height: 27px; } }

.custom-control-description {
	margin-left: 10px;
	vertical-align: text-bottom;
	color: #282828;
	font-size: 16px;
	font-weight: 300;
	margin-right: 10px; }

.custom-control-input {
	display: none; }

.custom-control-input:checked + .cr > .cr-icon {
	transform: scale(1) rotateZ(0deg);
	opacity: 1; }

.custom-control-input + .cr > .cr-icon {
	transform: scale(3) rotateZ(-20deg);
	opacity: 0;
	transition: all .2s ease-in; }

.custom-control-indicator {
	position: absolute;
	top: 6px;
	left: 0;
	display: block;
	width: 12px;
	height: 12px;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	border: 1px solid $blue;
	border-radius: 2px; }

.checkbox-container {
	display: inline-block;
	@media screen and (max-width: 600px) {
		float: left; } }

.quantity-container-wrapper {
	display: inline-block;
	margin-left: 10px; }

.related-block-quantity {
	float: right;
	text-align: center;
	@media screen and (max-width: 1229px) {
		float: left; }
	@media screen and (max-width: 1199px) {
		float: right; }
	@media screen and (max-width: 600px) {
		float: left;
		margin-bottom: 50px; } }

.quantity-container {
	display: inline-block;
	width: 24px;
	height: 24px;
	border: 1px solid #c3c3c3;
	color: #6ca9c4;
	vertical-align: bottom;
	cursor: pointer;
	-moz-user-select: none;
	-khtml-user-select: none;
	user-select: none;
	i {
		font-size: 9px;
		vertical-align: unset; } }

.quantity-number {
	width: 30px;
	padding: 0 5px;
	color: #6ca9c4;
	font-size: 16px;
	font-weight: 300;
	border: none;
	text-align: center;
	margin-right: 3px; }

.quantity-text {
	color: #282828;
	font-size: 16px;
	font-weight: 300;
	line-height: 26px;
	margin-left: 17px;
	@media screen and (max-width: 1199px) {
		margin-right: 20px; } }

.information-client {
	padding: 50px 0;
	background-color: rgba(242,250,252,0.5); }

.information-label {
	display: block;
	clear: both;
	color: #282828;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
	margin-bottom: 25px; }

.information-input {
	float: right;
	width: 350px;
	padding-bottom: 5px;
	background-color: transparent;
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #cdcdcd;
	&:hover,
	&:focus {
		border-bottom: 1px solid rgba(96,160,190,0.6); }
	@media screen and (max-width: 600px) {
		width: 50%; }
	@media screen and (max-width: 480px) {
		float: none;
		display: block;
		width: 100%; } }

.time-block {
	display: block;
	margin-top: 50px;
	color: #282828;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px; }

.calendar-input-container1,
.calendar-input-container2 {
	position: relative;
	width: 159px;
	height: 40px;
	float: right; }

.calendar-input {
	display: inline-block;
	padding-left: 40px;
	width: 159px;
	height: 40px;
	border: 1px solid #599dbc;
	background-color: #ffffff;
	border-radius: 0;
	color: #282828;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	margin-top: -8px;
	-webkit-appearance: none; }

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	border-radius: 50%;
	background-color: #599dbc;
	height: 10px;
	width: 10px; }

.calendar-icon1 {
	position: absolute;
	top: 3px;
	right: 130px;
	z-index: 1;
	color: #5faca4; }

.calendar-icon2 {
	position: absolute;
	right: 130px;
	top: 4px;
	z-index: 1;
	color: #5faca4; }

.word-from-to {
	float: right;
	margin-left: 20px;
	margin-right: 10px; }

.fa.chevron-icon3,
.fa.chevron-icon1 {
	position: absolute;
	right: 13px;
	top: 5px;
	z-index: 1;
	color: #5faca4;
	font-size: 11px; }

.fa.chevron-icon2 {
	position: absolute;
	right: 13px;
	top: 3px;
	z-index: 1;
	color: #5faca4;
	top: 5px;
	font-size: 11px; }

.fa.chevron-icon3 {
	right: 219px;
	@media screen and (max-width: 600px) {
		right: 13px; } }

.time-select {
	width: 159px;
	height: 40px;
	border: 1px solid rgba(89, 157, 188, 0.7);
	background-color: #ffffff;
	float: right;
	margin-right: 207px;
	margin-top: -10px;
	-webkit-appearance: none;
	color: #282828;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	padding-left: 20px;
	cursor: pointer;
	border-radius: 0;
	@media screen and (max-width: 600px) {
		float: none;
		margin-right: 0; } }

.time-block__mart {
	margin-bottom: 65px;
	margin-top: 65px;
	@media screen and (max-width: 600px) {
		margin-top: 100px; } }

.total-rent-container {
	height: 16px;
	color: #4a4a4a;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	margin-top: 35px; }

.calendar-input-container-wrap {
	position: relative;
	margin-top: -22px;
	@media screen and (max-width: 600px) {
		width: 300px;
		float: right; } }

.calendar-block2 {
	@media screen and (max-width: 600px) {
		position: absolute;
		top: 50px;
		right: 0; } }

.rent-data {
	color: #6da9c4; }

.rent__marl {
	margin-left: 10px; }

.rent-total {
	font-family: $RobotoCondenced;
	font-weight: 700;
	text-transform: uppercase;
	float: right; }

.delivery-date {
	padding: 50px 0; }

.delivery-radio-label {
	color: #2c2c2c;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
	width: 281px;
	margin-bottom: 60px; }

.delivery-radio-label input[type="radio"] {
	display: none; }

.cr {
	position: relative;
	display: inline-block;
	border: 1px solid #599dbc;
	width: 14px;
	height: 14px;
	float: left;
	margin-top: 4px;
	margin-right: 64px;
	border-radius: 50%; }

.delivery-radio-label input[type="radio"] + .cr > .cr-icon {
	opacity: 0;
	transition: all .1s ease-in; }

.delivery-radio-label	input[type="radio"]:checked {
	& + .cr > .cr-icon {
		opacity: 1; } }

.fa.cr-icon {
	font-size: 7px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); }

.self-delivery-wrapper {
	display: inline-block;
	position: relative; }

.self-delivery-icon {
	background: url('../img/content/self-delivery-icon.png') 0 0 no-repeat;
	background-size: 49px 49px;
	width: 49px;
	height: 49px;
	position: absolute;
	top: -20px;
	left: 20px; }

.delivery-icon {
	background: url('../img/content/delivery-icon.png') 0 0 no-repeat;
	width: 46px;
	height: 28px;
	position: absolute;
	top: -3px;
	left: 25px; }

.related-block-title__marb {
	margin-bottom: 60px; }

.delivery-input {
	width: 100%; }

.total-rent-container__mart {
	margin-top: 50px; }

.total-rent-container__martop {
	margin-top: 18px; }

.total-rent-container__ttu {
	text-transform: uppercase; }

.icon-non-cash,
.icon-cash {
	width: 44px;
	height: 35px;
	position: absolute;
	top: -6px;
	left: 25px;
	fill: #59a9a1; }

.online-payment-icon {
	background: url('../img/content/online-payment.png') 0 0 no-repeat;
	width: 47px;
	height: 47px;
	position: absolute;
	top: -8px;
	left: 21px; }

.rent-btn {
	width: 236px;
	margin-top: 60px;
	cursor: pointer; }

.cr2 {
	border-radius: 2px;
	margin-right: 10px;
	margin-top: 13px;
	@media screen and (max-width: 480px) {
		position: absolute;
		left: -20px;
		top: -7px; } }

.glyphicon.glyphicon-ok {
	font-size: 11px;
	top: -14px;
	@media screen and (max-width: 480px) {
		top: -8px; } }

.icon-check-ok {
	display: none;
	position: absolute;
	width: 15px;
	height: 16px;
	right: -15px;
	top: 5px;
	fill: #5da0be;
	opacity: 0.9;
	&.active {
		display: block; } }
