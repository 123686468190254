@import 'fonts';
@import 'vars';
@import 'libs';
@import 'utils/mixins';

html {
	line-height: 1.3;
	font-size: 16px; }


body {
	font-family: $Roboto; }


h1,h2,h3,h4,h5,h6 {
	margin: 0; }


ul,li {
	margin: 0;
	padding: 0;
	list-style-type: none; }

p {
	margin: 0; }

a,
a:hover {
	text-decoration: none;
	color: black; }

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle; }


textarea {
	resize: none; }


section {
	position: relative; }


input,
select,
button {
	outline: none; }


*, *:before, *:after {
	box-sizing border-box {} }


// Общие стили
@import 'common-styles';


// МОДУЛИ
// Стили шапки
@import 'modules/header/header';

// Стили футера
@import 'modules/footer/footer';

// Стили меню
@import 'modules/navigation/main-nav';

//Компоненты
@import "components/components";


// СТРАНИЦЫ
// Стили главной страницы
@import 'pages/index';
@import 'pages/category';
@import 'pages/card';
@import 'pages/rent';
