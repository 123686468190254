@media (min-width: 1230px) {
	.container {
		width: 1200px; } }

html,
body {
	overflow-x: hidden; }

h1 {
	color: #4d4d4d;
	font-family: $RobotoCondenced;
	font-size: 34px;
	font-weight: 700;
	line-height: 54px;
	text-transform: uppercase;
	position: relative;
	margin-left: 30px;
	z-index: 1;
	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		top: 26px;
		left: -30px;
		width: 22px;
		height: 3px;
		background-color: #5da0be; }
	@media screen and (max-width: 991px) {
		font-size: 29px; }
	@media screen and (max-width: 600px) {
		font-size: 25px; }
	@media screen and (max-width: 480px) {
		font-size: 20px;
		line-height: 34px;
		margin-left: 23px;
		&::before {
			top: 15px;
			width: 16px;
			left: -22px; } } }
h2 {
	color: #5da0be;
	font-family: $RobotoCondenced;
	font-size: 24px;
	font-weight: 700;
	line-height: 54px;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
	@media screen and (max-width: 1229px) {
		font-size: 18px; } }

img {
	max-width: 100%; }

.padnone {
	padding-left: 0;
	padding-right: 0; }

.padrn {
	padding-right: 0; }

.padln {
	padding-left: 0; }

.tac {
	text-align: center; }

.fa {
	color: #5b9fbd; }

.btn-white {
	display: inline-block;
	position: relative;
	width: 199px;
	height: 49px;
	color: #282828;
	font-weight: 500;
	line-height: 46px;
	border-radius: 23px;
	border: 1px solid #599dbc;
	text-align: center;
	transition: 0.2s ease;
	z-index: 1;
	&:hover {
		color: #fffefe;
		background-color: #5da0be; } }

.complect:hover .card-hover,
.lamp:hover .card-hover,
.physioadispersers:hover .card-hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(3,158,191,0.15);
	cursor: pointer;
	-webkit-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
	-moz-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
	box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
	z-index: 1;
	transition: 0.2s ease; }

.form-input {
	display: inline-block;
	width: 46%;
	margin-right: 7%;
	color: #022828;
	font-weight: 300;
	line-height: 30px;
	font-size: 16px;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(146,147,150,0.3);
	margin-bottom: 33px;
	border-radius: 0;
	&:nth-child(2) {
		margin-right: 0;
		border-bottom: 1px solid rgba(146,147,150,0.6); }
	&:hover,
	&:focus {
		border-bottom: 1px solid rgba(24,161,193,0.6); }
	@media screen and (max-width: 400px) {
		width: 100%; } }

.btn-blue {
	float: right;
	width: 223px;
	height: 47px;
	color: #fff;
	font-weight: 500;
	line-height: 44px;
	border-radius: 23px;
	text-align: center;
	transition: 0.2s ease;
	background-color: #599dbc;
	font-size: 16px;
	border: 1px solid #599dbc;
	&:hover {
		-webkit-box-shadow: 0px 10px 17px -5px rgba(0,0,0,0.35);
		-moz-box-shadow: 0px 10px 17px -5px rgba(0,0,0,0.35);
		box-shadow: 0px 10px 17px -5px rgba(0,0,0,0.35); }
	@media screen and (max-width: 991px) {
		display: block;
		float: none;
		margin: auto; } }

.bgrc-light {
	background-color: #fbfbfb; }

.bgrc-dark {
	background-color: #f7f7f7; }
