.lease {
	position: relative; }

.object-left {
	position: absolute;
	left: 0;
	bottom: 0;
	@media screen and (max-width: 1400px) {
		display: none; } }

.object-right {
	position: absolute;
	top: -100px;
	right: 0;
	z-index: -1;
	@media screen and (max-width: 1400px) {
		display: none; } }

.lease-section-left {
	padding-top: 105px;
	padding-bottom: 123px;
	@media screen and (max-width: 480px) {
			padding: 40px 15px; }
	h1 {
		font-size: 30px;
		max-width: 526px;
		margin-bottom: 72px;
		line-height: 44px;
		letter-spacing: -1px;
		&::before {
			top: 20px; }
		@media screen and (max-width: 1229px) {
			font-size: 22px;
			line-height: 34px;
			margin-left: 11px;
			&::before {
				top: 15px;
				width: 16px;
				left: -22px; } }
		@media screen and (max-width: 480px) {
			font-size: 20px;
			margin-bottom: 35px; } }
	a {
		margin-left: 30px;
		@media screen and (max-width: 1229px) {
			margin-left: 11px; } } }

.lease-section-right {
	position: absolute;
	height: 483px;
	width: 50%;
	right: 0;
	background: rgba(245,245,245,1);
	background: -moz-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(224,224,224,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(245,245,245,1)), color-stop(100%, rgba(224,224,224,1)));
	background: -webkit-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(224,224,224,1) 100%);
	background: -o-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(224,224,224,1) 100%);
	background: -ms-linear-gradient(left, rgba(245,245,245,1) 0%, rgba(224,224,224,1) 100%);
	background: linear-gradient(to right, rgba(245,245,245,1) 0%, rgba(224,224,224,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='#e0e0e0', GradientType=1 );
	z-index: -2; }

.main-device {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 147px;
	right: 0;
	width: 928px;
	height: 576px;
	margin: 0 auto;
	z-index: 1;
	@media screen and (max-width: 1024px) {
		left: 114px; }
	@media screen and (max-width: 991px) {
		left: 0; }
	@media screen and (max-width: 630px) {
		display: none; } }

.equipment {
	padding-top: 70px;
	margin-bottom: 90px;
	background: rgba(246,246,246,1);
	background: -moz-linear-gradient(top, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(246,246,246,1)), color-stop(100%, rgba(255,255,255,1)));
	background: -webkit-linear-gradient(top, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
	background: -o-linear-gradient(top, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
	background: -ms-linear-gradient(top, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#ffffff', GradientType=0 );
	h1 {
		margin-bottom: 50px;
		@media screen and (max-width: 480px) {
			margin-left: 30px; } }
	@media screen and (max-width: 991px) {
		padding-top: 50px;
		margin-bottom: 60px; } }

.equipment-s {
	margin-bottom: 40px; }

.physioadispersers {
	height: 433px;
	background-color: #fff;
	position: relative;
	padding: 15px 15px 0 15px;
	@media screen and (max-width: 991px) {
		margin-bottom: 30px; }
	@media screen and (max-width: 480px) {
		height: 198px; }
	h2 {
		margin-left: 33px;
		padding-top: 35px;
		@media screen and (max-width: 1229px) {
			padding-top: 10px; }
		@media screen and (max-width: 480px) {
			margin-left: 0;
			text-align: center;
			line-height: 30px; } } }

.physioadisperser-img {
	position: absolute;
	top: 110px;
	right: 0;
	z-index: 0;
	@media screen and (max-width: 480px) {
		opacity: 0.2;
		top: initial;
		bottom: 0; } }

.object-img {
	width: 282px;
	height: 110px;
	position: absolute;
	left: 48px;
	bottom: 0;
	opacity: 0.07;
	@media screen and (max-width: 480px) {
		display: none; } }

.lamp {
	height: 198px;
	background-color: #fff;
	position: relative;
	padding: 15px 15px 0 15px;
	h2 {
		margin-left: 33px;
		padding-top: 35px;
		@media screen and (max-width: 1229px) {
			padding-top: 10px; }
		@media screen and (max-width: 480px) {
			line-height: 30px;
			margin-left: 0;
			text-align: center; } }
	.object-img {
		width: 234px;
		height: 70px;
		position: absolute;
		left: 0;
		bottom: 0;
		opacity: 0.07; } }

.lamp-img {
	position: absolute;
	top: 0;
	right: 30px;
	width: 159px;
	height: 184px;
	@media screen and (max-width: 480px) {
		opacity: 0.2; } }

.complect {
	height: 198px;
	width: auto;
	background-color: #fff;
	position: relative;
	margin-top: 37px;
	padding: 0 15px;
	@media screen and (max-width: 991px) {
		margin-top: 30px; }
	h2 {
		float: right;
		line-height: 30px;
		margin-top: 30px;
		margin-right: 20px;
		text-align: right;
		@media screen and (max-width: 1229px) {
			padding-top: 10px; }
		@media screen and (max-width: 320px) {
			margin-left: 0;
			text-align: center; } }
	.object-img {
		width: 275px;
		height: 80px;
		position: absolute;
		right: 38px;
		left: initial;
		bottom: 0;
		opacity: 0.07; } }

.complect-img {
	position: absolute;
	top: 9px;
	left: 10px;
	width: 286px;
	height: 191px;
	@media screen and (max-width: 1229px) {
		height: 165px; }
	@media screen and (max-width: 480px) {
		opacity: 0.2; } }

.work-scheme {
	padding-right: 15px;
	padding-left: 15px;
	h1 {
		margin-top: 40px;
		@media screen and (max-width: 480px) {
			margin-left: 15px; } } }

.work-scheme-bottom {
	margin-bottom: 50px;
	@media screen and (max-width: 991px) {
		margin-bottom: 0; } }

.work-scheme-container {
	position: relative;
	width: 107px;
	height: 107px;
	border-radius: 50%;
	background-color: #EBEBEB;
	margin-left: 32px;
	margin-bottom: 30px;
	@media screen and (max-width: 991px) {
		margin: 0 auto 30px; }
	@media screen and (max-width: 600px) {
		margin-left: 15px; } }

.scheme-icon1 {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-100%,-50%); }

.work-scheme-text {
	display: inline-block;
	width: 264px;
	height: 75px;
	color: #353535;
	font-family: $RobotoCondenced;
	font-size: 20px;
	text-transform: uppercase;
	margin-left: 75px;
	span {
		color: #5da0be;
		font-size: 26px;
		font-weight: 700;
		line-height: 40px;
		@media screen and (max-width: 320px) {
			font-size: 18px;
			line-height: 25px; } }
	@media screen and (max-width: 1229px) {
		font-size: 18px;
		width: 210px; }
	@media screen and (max-width: 1050px) {
		font-size: 16px;
		width: 181px; }
	@media screen and (max-width: 320px) {
		font-size: 14px;
		width: 162px; } }

.advantages {
	background-color: #f8f8f8; }

.advantages-left {
	padding: 70px 15px 120px 15px;
	overflow: hidden;
	@media screen and (max-width: 480px) {
		padding: 50px 0; } }

.advantages-left-text {
	max-width: 484px;
	color: #282828;
	font-family: $RobotoCondenced;
	font-size: 26px;
	line-height: 40px;
	margin-top: 80px;
	margin-bottom: 80px;
	span {
		color: #5da0be;
		font-weight: 700;
		text-transform: uppercase; }
	@media screen and (max-width: 480px) {
		margin: 30px 0;
		font-size: 20px;
		line-height: 30px; } }

.advantages-left-desc {
	max-width: 547px;
	color: #282828;
	font-size: 20px;
	font-weight: 300;
	line-height: 34px;
	@media screen and (max-width: 480px) {
		font-size: 16px;
		line-height: 26px; } }

.advantages-object-left {
	width: 278px;
	height: 190px;
	opacity: 0.05; }

.advantages-object-right {
	width: 383px;
	height: 188px;
	top: -83px;
	right: -192px;
	z-index: 0;
	opacity: 0.05; }

.advantages-right {
	overflow: hidden;
	position: absolute;
	right: 0;
	width: 50%;
	height: 100%;
	@media screen and (max-width: 991px) {
		width: 100%;
		height: 575px; } }

.advantages-bgr {
	position: absolute;
	min-width: 150%;
	height: 150%;
	margin-left: -1000px;
	max-width: initial;
	opacity: 0.2;
	@media screen and (max-width: 991px) {
		margin-left: -795px; } }

.advantages-container {
	display: inline-block;
	position: relative;
	text-align: center;
	width: 250px;
	img {
		margin-bottom: 30px;
		@media screen and (max-width: 1229px) {
			margin-right: 30px; }
		@media screen and (max-width: 480px) {
			margin-right: 15px; } }
	@media screen and (max-width: 1229px) {
		display: block;
		width: initial;
		text-align: left;
		margin-left: 30px; } }

.advantages-title {
	color: #3c3c3c;
	font-family: $RobotoCondenced;
	font-size: 20px;
	&::before {
		content: "";
		display: inline-block;
		position: absolute;
		width: 33px;
		height: 3px;
		background-color: #5da0be;
		margin: 0 auto;
		left: 0;
		right: 0;
		bottom: 68px; }
	@media screen and (max-width: 1229px) {
		display: inline-block;
		&::before {
			height: 0; } }
	@media screen and (max-width: 480px) {
		font-size: 16px;
		max-width: 150px; } }

.advantages-items-row {
	text-align: center;
	margin-bottom: 78px;
	&:last-child {
		margin-bottom: 0; }
	@media screen and (max-width: 1229px) {
		margin-bottom: 0; } }

.advantages-items {
	padding: 124px 0;
	@media screen and (max-width: 1229px) {
		padding: 125px 0 65px 0; }
	@media screen and (max-width: 991px) {
		padding: 50px 0 0 0; } }

.companies-manufacturers {
	padding: 90px 15px 100px;
	h1 {
		margin-bottom: 70px; }
	@media screen and (max-width: 991px) {
		margin-top: 575px;
		padding: 90px 0 100px;
		h1 {
			margin-left: 40px; } } }

.owl-carousel .owl-item img {
	width: initial; }

.brand1 {
	height: 72px;
	margin: 0 auto; }

.owl-carousel .owl-nav {
	position: absolute;
	top: -110px;
	right: 0; }

.owl-prev,
.owl-next {
	display: inline-block;
	width: 45px;
	height: 45px;
	background-color: #5da0be;
	text-align: center;
	line-height: 45px;
	.fa {
		color: #fff; } }

.owl-prev {
	&:hover {
		opacity: 0.9; } }

.owl-next {
	opacity: 0.85;
	&:hover {
		opacity: 0.5; } }

.form {
	background-color: #f8f8f8;
	position: relative; }

.object-form-right {
	width: 281px;
	height: 200px;
	position: absolute;
	top: 0;
	right: 0;
	@media screen and (max-width: 1690px) {
		display: none; } }

.object-form-left {
	width: 362px;
	height: 119px;
	position: absolute;
	bottom: 0;
	left: -248px;
	@media screen and (max-width: 1690px) {
		display: none; } }

.form-left {
	overflow: hidden;
	position: absolute;
	left: 0;
	width: 40%;
	height: 100%;
	@media screen and (max-width: 991px) {
		display: none; } }

.form-bgr {
	position: absolute;
	min-width: 200%;
	height: 200%;
	margin-top: -362px;
	margin-left: -660px;
	max-width: initial;
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	filter: FlipV;
	-ms-filter: FlipV; }

.form-left-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	background-color: #599dbc;
	opacity: 0.85; }

.form-pic {
	width: 338px;
	height: 473px;
	position: absolute;
	bottom: 0;
	left: -390px;
	z-index: 1;
	@media screen and (max-width: 991px) {
		display: none; } }

.form-right {
	position: relative;
	padding: 65px 0 50px;
	h1 {
		margin-bottom: 40px;
		@media screen and (max-width: 480px) {
			margin-bottom: 30px;
			margin-left: 23px; } } }

.form-right-desc {
	font-size: 20px;
	font-weight: 300;
	line-height: 34px;
	margin-bottom: 50px;
	@media screen and (max-width: 480px) {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 35px; } }
