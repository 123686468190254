.breadcrumb {
	padding: 0;
	margin-top: 40px;
	margin-bottom: 35px;
	background-color: transparent;
	li a {
		color: #636363;
		font-size: 14px; } }

.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #636363;
  content: "\f178";
  font-family: FontAwesome; }

.breadcrumb .active span {
	color: #636363;
	border-bottom: 1px solid rgba(89,157,188,0.7); }

.physio {
	h1 {
		margin-bottom: 35px; } }

.physio-container-wrap {
	padding: 0 15px; }

.physio-container {
	height: 313px;
	padding: 0 15px;
	&:hover {
		cursor: pointer;
		-webkit-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
		-moz-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
		box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
		position: relative;
		z-index: 1;
		transition: 0.2s ease;
		.physio-text-container h3 {
			color: #599dbc; }
		.read-more {
			color: #018caa; } }
	@media screen and (max-width: 480px) {
		padding-top: 5px;
		height: 430px; } }

.physio-img-container {
	display: inline-block;
	width: 60%;
	position: relative;
	height: 313px;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%); }
	@media screen and (max-width: 480px) {
		display: block;
		width: 100%;
		height: 40%;
		overflow: hidden; } }

.physio-text-container {
	display: inline-block;
	width: 36%;
	float: right!important;
	h3 {
		position: absolute;
		color: #464646;
		font-family: $RobotoCondenced;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
		margin-top: 50px;
		margin-bottom: 35px;
		span {
			color: #599dbc; }
		@media screen and (max-width: 991px) {
			font-size: 13px; } }
	@media screen and (max-width: 480px) {
		width: 100%;
		h3 {
			margin-top: 20px; } } }

.producer {
	color: #9d9d9d;
	font-size: 14px;
	margin-top: 127px;
	@media screen and (max-width: 480px) {
		margin-top: 74px; } }

.lease-time {
	color: #464646;
	font-size: 16px;
	margin: 5px 0 10px; }

.lease-price {
	color: #599dbc;
	font-family: $RobotoCondenced;
	font-size: 32px;
	font-weight: 700;
	line-height: 24px;
	text-transform: uppercase;
	margin-bottom: 40px; }

.read-more {
	color: #282828;
	font-size: 16px;
	margin-right: 5px;
	border-bottom: 1px solid #039ebf; }

.related-products-section {
	h1 {
		margin-top: 55px; } }

.related-text-container {
	float: none;
	width: 100%;
	position: relative;
	@media screen and (max-width: 1229px) {
		h3 {
			font-size: 14px; } } }

.related-product-container {
	height: 451px;
	padding: 15px 30px 0 30px;
	&:hover {
		cursor: pointer;
		-webkit-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
		-moz-box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
		box-shadow: 0px 8px 30px 0px rgba(0,0,0,0.34);
		position: relative;
		z-index: 1;
		transition: 0.2s ease;
		.read-more {
			color: #018caa; } } }

.related-img-container {
	position: relative;
	height: 36%;
	overflow: hidden;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		@media screen and (max-width: 767px) {
			max-width: 230px; } } }

.related-text-container {
	height: 60%;
	.producer {
		margin-top: 115px;
		margin-bottom: 15px; }
	h3 {
		margin-top: 20px;
		letter-spacing: -0.48px; }
	.quantity {
		color: #464646; } }

.physio .companies-manufacturers {
	@media screen and (max-width: 991px) {
		margin-top: 0; } }

.bgr-wrap .bgrc-light:first-child {
	@media screen and (min-width: 768px) and (max-width: 991px) {
		background-color: #f7f7f7; } }

.bgr-wrap .bgrc-dark:last-child {
	@media screen and (min-width: 768px) and (max-width: 991px) {
		background-color: #fbfbfb; } }

.physio-bgr-wrap .bgrc-light:first-child {
	@media screen and (max-width: 767px) {
		background-color: #f7f7f7; } }

.physio-bgr-wrap .bgrc-dark:last-child {
	@media screen and (max-width: 767px) {
		background-color: #fbfbfb; } }
