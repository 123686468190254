footer h1 {
	margin-top: 65px;
	margin-bottom: 60px;
	@media screen and (max-width: 991px) {
		margin-top: 40px;
		margin-bottom: 40px; } }

.address-title {
	font-family: $RobotoCondenced;
	font-size: 20px;
	line-height: 30px;
	text-transform: uppercase;
	margin-bottom: 20px; }

.address-index {
	display: inline-block;
	margin-left: 7px; }

.address-info {
	margin-left: 20px;
	margin-bottom: 50px;
	&:last-of-type {
		margin-bottom: 20px; } }

.address-mail {
	margin-left: 8px; }

.footer-contacts .fa-mobile {
	vertical-align: middle;
	font-size: 18px; }

.fa.fa-envelope {
	font-size: 11px; }

.mymap {
	position: absolute;
	right: 0;
	height: 542px;
	width: 50%;
	@media screen and (max-width: 991px) {
		width: 100%;
		height: 300px;
		padding: 0; } }

.mymap [class$="ground-pane"] {
	filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http: //www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
	filter: grayscale(100%); }

.footer-contacts {
	height: 542px;
	@media screen and (max-width: 991px) {
		height: 415px; } }

.footer-social {
	min-height: 120px;
	background-color: #e3e3e3;
	@media screen and (max-width: 991px) {
		margin-top: 300px; } }

.footer-social-left {
	float: left;
	margin-top: 35px;
	@media screen and (max-width: 540px) {
		width: 100%;
		text-align: center;
		margin-top: 20px; } }

.footer-social-website {
	font-size: 14px;
	line-height: 28px; }

.footer-social-right {
	float: right;
	line-height: 120px;
	.fa {
		font-size: 18px;
		margin-left: 30px;
		&:hover {
			color: #00b5b3; } }
	@media screen and (max-width: 540px) {
		width: 100%;
		text-align: center;
		line-height: 60px;
		.fa {
			margin: 0 15px; } } }
