.card-scheme {
	background-color: $blue;
	h1 {
		margin-bottom: 50px;
		color: #fff;
		&:before {
			background-color: #fff; } }
	.work-scheme {
		padding-bottom: 50px; }
	.work-scheme-container {
	 background-color: rgba(255,255,255,0.2); }
	.work-scheme-text {
		color: #fff;
		span {
			color: #fff; } } }

.fotorama__nav-wrap {
	position: absolute;
	top: 0;
	right: -406px;
	@media screen and (max-width: 1229px) {
		right: -323px; }
	@media screen and (max-width: 991px) {
		position: initial; }
	@media screen and (max-width: 767px) {
		display: none; } }
.fotorama__nav__frame.fotorama__nav__frame--thumb.fotorama__active {
	display: block;
	&:hover {
		opacity: 1; }
	@media screen and (max-width: 991px) {
		display: inherit; } }

.fotorama__nav__frame.fotorama__nav__frame--thumb {
	display: block;
	&:hover {
		opacity: 0.6; }
	@media screen and (max-width: 991px) {
		display: inherit; } }

.fotorama__thumb-border {
	display: none; }

.fotorama__wrap {
 }	//background-color: #f8f8f8

.fotorama__nav__frame.fotorama__nav__frame--thumb.fotorama__active {
	border: 1px solid #5da0be; }

.btn-blue-slider {
	width: 234px;
	float: left;
	margin-bottom: 17px;
	&:hover {
		color: #232323;
		box-shadow: none;
		background-color: #fff;
		border: 1px solid $blue; }
	@media screen and (max-width: 991px) {
		width: 151px;
		font-size: 13px; }
	@media screen and (max-width: 767px) {
		margin-right: 10px; }
	@media screen and (max-width: 480px) {
		width: 234px;
		font-size: 16px;
		float: none;
		margin: 0 auto 10px; } }

.btn-white-slider {
	width: 234px;
	height: 47px;
	font-size: 16px;
	cursor: pointer;
	@media screen and (max-width: 991px) {
		width: 151px;
		font-size: 13px;
		line-height: 22px; }
	@media screen and (max-width: 480px) {
		display: block;
		width: 234px;
		font-size: 16px;
		line-height: 46px;
		float: none;
		margin: 0 auto; } }

.physio-producer {
	color: #636363;
	font-size: 16px;
	line-height: 30px;
	@media screen and (max-width: 991px) {
		line-height: 24px;
		margin-bottom: 20px; } }

.stock {
	margin-top: 35px;
	margin-bottom: 17px;
	i {
		font-size: 18px;
		margin-right: 12px;
		vertical-align: middle; } }

.nav-pills-bgr {
	position: absolute;
	top: 60px;
	height: 100px;
	width: 100%;
	background-color: #f8f8f8;
	@media screen and (max-width: 670px) {
		height: 230px; } }

.nav.nav-pills {
	height: 100px;
	margin-top: 60px;
	background-color: #f8f8f8;
	display: flex;
	justify-content: space-around;
	align-items: center;
	a {
		color: #4d4d4d;
		font-family: $RobotoCondenced;
		font-size: 20px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
		@media screen and (max-width: 991px) {
			font-size: 14px; } }
	@media screen and (max-width: 670px) {
		height: 230px;
		flex-direction: column; } }

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
	color: #8f8f8f;
	background-color: transparent; }

.nav > li > a:hover, .nav > li > a:focus {
	text-decoration: none;
	background-color: transparent; }

.icon-brand,
.icon-delivery,
.icon-comment,
.icon-scale {
	position: absolute;
	top: 12px;
	fill: $blue;
	@media screen and (max-width: 991px) {
		top: 16px; } }

.icon-scale {
	width: 23px;
	height: 17px;
	left: -16px;
	@media screen and (max-width: 991px) {
		width: 15px;
		height: 11px;
		left: -3px; } }

.icon-comment {
	width: 24px;
	height: 23px;
	left: -16px;
	@media screen and (max-width: 991px) {
		width: 20px;
		height: 16px;
		left: -5px;
		top: 14px; } }

.icon-delivery {
	width: 27px;
	height: 18px;
	left: -20px;
	@media screen and (max-width: 991px) {
		width: 20px;
		height: 16px;
		left: -7px;
		top: 14px; } }

.icon-brand {
	width: 24px;
	height: 28px;
	top: 8px;
	left: -14px;
	@media screen and (max-width: 991px) {
		width: 20px;
		height: 16px;
		left: -5px;
		top: 14px; } }

.search-icon {
	margin-left: -20px;
	&::before {
		content: "\f002";
		font-family: FontAwesome;
		color: $blue;
		margin-right: 8px;
		@media screen and (max-width: 991px) {
			margin-right: 4px; } } }

.module-title {
	display: inline-block;
	white-space: nowrap;
	width: 47px;
	color: #4d4d4d;
	font-family: $RobotoCondenced;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 40px;
	border-bottom: 1px solid $blue;
	padding-bottom: 15px;
	margin-bottom: 17px; }

.module-desc {
	color: #000;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px; }

.module-desc-mar {
	margin-bottom: 20px; }

.module-read-more {
	margin-top: 70px;
	i {
		margin-left: 3px; }
	&:hover a {
		color: $blue; } }

.characteristics-title {
	margin: 45px 0 35px;
	max-width: 700px;
	color: #4d4d4d;
	font-family: $RobotoCondenced;
	font-size: 20px;
	font-weight: 700;
	@media screen and (max-width: 480px) {
		font-size: 16px; } }

.table > tbody > tr > td {
	border-top: none; }

.table > tbody > tr > td.table-left {
	color: #000;
	font-size: 16px;
	font-weight: 300;
	padding: 20px 180px 20px 30px;
	@media screen and (max-width: 730px) {
		padding-left: 0;
		padding-right: 0;
		font-size: 14px; } }

.table > tbody > tr > td.table-right {
	color: #4d4d4d;
	font-size: 16px;
	font-weight: 700;
	line-height: 30px;
	padding-top: 15px;
	padding-bottom: 15px;
	padding-right: 200px;
	@media screen and (max-width: 730px) {
		padding-right: 0;
		font-size: 14px; } }

.doctor-name {
	color: #4d4d4d;
	font-family: $RobotoCondenced;
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 45px; }

.doctor-info {
	display: inline-block;
	color: #353535;
	font-size: 14px;
	font-weight: 300;
	white-space: nowrap;
	width: 47px;
	border-bottom: 1px solid $blue;
	padding-bottom: 15px;
	margin-bottom: 20px; }

.doctor-text {
	color: #353535;
	font-size: 16px;
	font-weight: 300;
	line-height: 26px;
	max-width: 680px;
	@media screen and (max-width: 480px) {
		font-size: 14px;
		line-height: 21px; } }

.doctor-section {
	margin-bottom: 80px;
	&:last-child {
		margin-bottom: 0; } }

.review-input {
	display: inline-block;
	width: 100%;
	height: 43px;
	padding: 0 13px;
	color: #7c7c7c;
	font-size: 14px;
	font-weight: 300;
	line-height: 26px;
	border-radius: 4px;
	border: 1px solid #d6d6d6;
	margin-bottom: 15px; }

textarea.review-input {
	height: 132px;
	outline: none; }

.form-review .btn-blue-slider {
	float: right;
	margin-top: 10px;
	@media screen and (max-width: 480px) {
		float: none; } }

.delivery-title {
	border-bottom: none;
	margin-bottom: 0;
	&:last-of-type {
		margin-top: 0; } }

.table > thead > tr > th {
	border-bottom: 1px solid $blue; }

.delivery-table {
	margin-bottom: 50px;
	@media screen and (max-width: 740px) {
		display: block;
		overflow: auto;
		max-width: 100%; } }

.delivery-table thead th {
	color: #353535;
	font-size: 16px;
	font-weight: 400;
	line-height: 29.97px; }

.delivery-table tbody td {
	color: #323232;
	font-size: 16px;
	font-weight: 300;
	line-height: 29.94px;
	i {
		color: #727272;
		font-size: 14px; } }

.delivery-table > thead > tr > th.padln {
	padding-left: 0; }

.delivery-table > tbody > tr > td.padln {
	padding-left: 0; }

.attention-title {
	color: #599dbc;
	font-family: $RobotoCondenced;
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase; }

.attention-info {
	color: #353535;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
	max-width: 882px; }

.brand__mar {
	margin-top: 95px; }

.brand__mar-bot {
	margin-bottom: 0; }

.tab-pane.active,
.tab-pane {
	padding-bottom: 50px; }

.modal-content {
	box-shadow: 0 6px 17px 1px rgba(0, 68, 100, 0.15);
	border-radius: 0;
	border: none; }

.modal-header {
	border-bottom: none;
	padding-bottom: 0; }

.modal-body {
	padding: 0 78px 78px 78px;
	text-align: center;
	@media screen and (max-width: 530px) {
		padding: 0 15px 30px 15px; } }

.close {
	opacity: 1;
	i {
		font-size: 30px;
		color: $blue;
		@media screen and (max-width: 420px) {
			font-size: 20px; } } }

.modal-title {
	color: $blue;
	font-family: $RobotoCondenced;
	font-size: 30px;
	font-weight: 700;
	line-height: 54px;
	text-transform: uppercase;
	margin-bottom: 20px;
	@media screen and (max-width: 530px) {
		font-size: 20px;
		line-height: 30px;
		margin-bottom: 10px; } }

.modal-info {
	color: #636363;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	margin-bottom: 50px;
	@media screen and (max-width: 530px) {
		font-size: 14px;
		line-height: 16px;
		margin-bottom: 30px; } }

.modal-input {
	display: block;
	width: 100%;
	padding: 10px 0;
	color: #282828;
	font-size: 16px;
	font-weight: 300;
	line-height: 22px;
	border: none;
	border-bottom: 1px solid rgba(217,217,217, 0.8);
	margin-bottom: 20px;
	border-radius: 0;
	&:hover,
	&:focus {
		border-bottom: 1px solid rgba(96,160,190,0.6); }
	@media screen and (max-width: 420px) {
		padding: 5px 0;
		margin-bottom: 10px; } }

.modal-btn {
	width: 316px;
	float: none;
	text-align: center;
	margin-top: 50px;
	margin-bottom: 0;
	@media screen and (max-width: 991px) {
		font-size: 16px; }
	@media screen and (max-width: 767px) {
		margin: 50px auto 0; }
	@media screen and (max-width: 420px) {
		width: 100%; } }
